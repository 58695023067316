import { jwtDecode } from 'jwt-decode'
import { gql } from '@urql/vue'
import { createClient, ssrExchange, fetchExchange, type Client } from '@urql/core'
import { cacheExchange } from '@urql/exchange-graphcache'
import { authExchange } from '@urql/exchange-auth'
import { defineNuxtPlugin } from '#app'

const ssrKey = '__URQL_DATA__'

const REFRESH_MUTATION = gql`
  mutation refreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      accessToken
    }
  }
`

const isTokenExpired = (token: string) => {
  if (!token) return true

  try {
    const decoded = jwtDecode(token)

    if (!decoded.exp) return true

    return decoded.exp < Date.now() / 1000
  } catch {
    return true
  }
}

const graphcache = cacheExchange({
  keys: {
    Avatar: () => null,
    Cover: () => null,
    Image: () => null,
    Location: (data) => data.id != null ? String(data.id) : null,
    PhotoModel: (data) => data.id != null ? String(data.id) : null,
    Photographer: (data) => data.id != null ? String(data.id) : null,
    Profile: (data) => data.id != null ? String(data.id) : null,
    SocialNetwork: (data) => data.id != null ? String(data.id) : null,
    User: (data) => data.id != null ? String(data.id) : null,
    Work: (data) => data.id != null ? String(data.id) : null,
  },
  // updates: {
  //   Mutation: {
  //     addUserLocation: (result, _args, cache) => {
  //       const newLocation = result.addUserLocation
  //
  //       cache.updateQuery({ query: CURRENT_USER_QUERY }, data => {
  //         if (!data?.me?.profile?.locations) return data
  //
  //         return {
  //           ...data,
  //           me: {
  //             ...data.me,
  //             profile: {
  //               ...data.me.profile,
  //               locations: [...data.me.profile.locations, newLocation],
  //             },
  //           },
  //         }
  //       })
  //     },
  //   },
  // },
})

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp, payload } = nuxtApp
  const ssr = ssrExchange({ isClient: import.meta.client })
  const config = useRuntimeConfig()
  const accessToken = useState('access-token')
  const isAuthenticated = useCookie('auth')

  // When the app is created in the browser, restore SSR state from Nuxt payload
  if (import.meta.client) {
    nuxtApp.hook('app:created', () => {
      const ssrData = payload[ssrKey]

      if (ssrData) ssr.restoreData(ssrData as Record<string, never>)
    })
  }

  // When the app has rendered on the server, send SSR state to the client
  if (import.meta.server) {
    nuxtApp.hook('app:rendered', () => {
      payload[ssrKey] = ssr.extractData()
    })
  }

  const buildHeaders = () => {
    const headers: Record<string, string> = {
      'Accept-Language': (nuxtApp.$i18n as { locale: Ref<string> }).locale.value,
    }

    if (import.meta.client) return headers

    const { cookie } = useRequestHeaders(['cookie'])

    if (!cookie) return headers

    headers.cookie = cookie

    return headers
  }

  const client = createClient({
    url: config.public.graphqlApiUrl as string,
    requestPolicy: 'network-only',
    fetchOptions: () => ({
      credentials: 'include',
      headers: buildHeaders(),
    }),
    exchanges: [
      graphcache,
      authExchange(async (utils) => ({
        addAuthToOperation: (operation) => accessToken.value
          ? utils.appendHeaders(operation, { Authorization: `Bearer ${accessToken.value}` })
          : operation,
        didAuthError: (error, _operation) => error.graphQLErrors.some((e) => e.extensions?.code === 'AUTHENTICATION_ERROR'),
        willAuthError: () => {
          if (import.meta.server) return false

          return isTokenExpired(accessToken.value as string)
        },
        refreshAuth: async () => {
          if (!isAuthenticated.value) return

          const response = await utils.mutate(REFRESH_MUTATION, { input: {} }, {
            fetchOptions: { credentials: 'include', headers: buildHeaders() },
          })

          if (response.error) isAuthenticated.value = undefined

          accessToken.value = response.data?.refreshToken?.accessToken
        },
      })),
      ssr,
      fetchExchange,
    ],
  })

  nuxtApp.provide('urql', client)
  vueApp.provide('$urql', client)
})

declare module '#app' {
  interface NuxtApp {
    $urql: Client
  }
}

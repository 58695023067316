<script setup lang="ts">
const { locale } = useI18n()
const config = useRuntimeConfig()

const title = 'Redexy'
const description = 'Exposing Creativity'
const image = `${config.public.publicUrl}/preview.png`

useHead({
  titleTemplate: (titleChunk) => titleChunk ? `Redexy - ${titleChunk}` : 'Redexy',
  htmlAttrs: {
    lang: locale,
  },
  meta: [
    { name: 'description', content: description },
    { name: 'msapplication-TileColor', content: '#18181b' },
    { name: 'msapplication-TileImage', content: '/ms-icon-144x144.png' },
    { name: 'theme-color', content: '#18181b' },
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', sizes: '16x16 32x32 48x48', href: '/favicon.ico' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '192x192', href: '/favicon-192x192.png' },
    { rel: 'icon', type: 'image/png', sizes: '512x512', href: '/favicon-512x512.png' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
  ],
})

useSeoMeta({
  description,
  ogDescription: description,
  ogImage: image,
  ogTitle: title,
  ogUrl: config.public.publicUrl,
  twitterCard: 'summary_large_image',
  twitterDescription: description,
  twitterImage: image,
  twitterTitle: title,
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <svg
    class="hidden-svg"
    viewBox="0 0 1 1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath
        id="squircle-clip-1"
        clipPathUnits="objectBoundingBox"
      >
        <path
          d="M 0,0.5
                C 0,0.0575  0.0575,0  0.5,0
                  0.9425,0  1,0.0575  1,0.5
                  1,0.9425  0.9425,1  0.5,1
                  0.0575,1  0,0.9425  0,0.5"
        />
      </clipPath>
      <clipPath
        id="squircle-clip-2"
        clipPathUnits="objectBoundingBox"
      >
        <path
          d="M 0,0.5
                C 0,0.115  0.115,0  0.5,0
                  0.885,0  1,0.115  1,0.5
                  1,0.885  0.885,1  0.5,1
                  0.115,1  0,0.885  0,0.5"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>
.hidden-svg {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap;
}
</style>


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index4nVS2Dd4a5t9zrU2j8coCxsqtPYupm_GEv_452EzeGhJUMeta } from "/app/app/pages/index.vue?macro=true";
import { default as modelsoaCo8wMo1evP9jRNCkbpvaXFTPb6Yc4nkrRUha64Jb8Meta } from "/app/app/pages/models.vue?macro=true";
import { default as photographers2sAqNFxAsTOcc39cgD54bOPr8T_45IcTSr7xKeCBcldb0Meta } from "/app/app/pages/photographers.vue?macro=true";
import { default as sign_45inaa93D1ix0P1Mb1EHX0ncjE_LNtJH9yHAWpW2OnD4lt4Meta } from "/app/app/pages/(auth)/sign-in.vue?macro=true";
import { default as sign_45upAhmQVhcYZki9Oy7hQitOg12_45x6nlAYAxtrWtj7eYZ2sMeta } from "/app/app/pages/(auth)/sign-up.vue?macro=true";
import { default as profile_45PgpRRXTDqUPW_ZPIJvKfpmUK6wJHnqQhpJZ2q_45zxg4Meta } from "/app/app/pages/(user)/profile.vue?macro=true";
import { default as settingswq_456a8ydbgG6wbfMwCpfnhex_45T_45qsHYXpOoJDGW8drsMeta } from "/app/app/pages/(user)/settings.vue?macro=true";
import { default as contacts5t6qJ5sp0mydxnHfyIszRQSnZ5tuX5PLBdwY0RY5yrYMeta } from "/app/app/pages/(legal)/contacts.vue?macro=true";
import { default as reset_45passwordsTA1Hg21lfZA2wcmB6jR8w5pIrmvpYmrv4pi6JGXTjkMeta } from "/app/app/pages/(auth)/reset-password.vue?macro=true";
import { default as security_45infoNsHKp9cvLeLV8FkScVPfEiq2ae3yx1_45E2XwZSAKbkeAMeta } from "/app/app/pages/(legal)/security-info.vue?macro=true";
import { default as privacy_45policy8oCneQX483eQsoIxPJEIkHVB3oS8a0wy2zRiAX3771AMeta } from "/app/app/pages/(legal)/privacy-policy.vue?macro=true";
import { default as terms_45of_45serviceWv4ehgjurLKCSQbw34g4XFeadX7mEjlBxVb_LPizIlsMeta } from "/app/app/pages/(legal)/terms-of-service.vue?macro=true";
import { default as _91username_938ajVcLGSPt2MqXQ5bCX58e9bnPiv_dYAJIS7EXZ3IEwMeta } from "/app/app/pages/(profiles)/md/[username].vue?macro=true";
import { default as _91username_93nkGTfWD9ezmSbTTVILpey2vmlTdJ86ECKhkP_ZiwYPsMeta } from "/app/app/pages/(profiles)/ph/[username].vue?macro=true";
import { default as _91token_93Xl8a0U68WgXIQ0MAb6WaLnGySeLQOa1wMzeM_hOTAy0Meta } from "/app/app/pages/(auth)/confirmation/[token].vue?macro=true";
import { default as _91token_93yYDVE68RAJ_2mdcpcl0hPAsSvrmQF2ub0xKmiqPloU4Meta } from "/app/app/pages/(auth)/reset-password-confirm/[token].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/app/pages/index.vue")
  },
  {
    name: "models",
    path: "/models",
    meta: modelsoaCo8wMo1evP9jRNCkbpvaXFTPb6Yc4nkrRUha64Jb8Meta || {},
    component: () => import("/app/app/pages/models.vue")
  },
  {
    name: "photographers",
    path: "/photographers",
    meta: photographers2sAqNFxAsTOcc39cgD54bOPr8T_45IcTSr7xKeCBcldb0Meta || {},
    component: () => import("/app/app/pages/photographers.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45inaa93D1ix0P1Mb1EHX0ncjE_LNtJH9yHAWpW2OnD4lt4Meta || {},
    component: () => import("/app/app/pages/(auth)/sign-in.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upAhmQVhcYZki9Oy7hQitOg12_45x6nlAYAxtrWtj7eYZ2sMeta || {},
    component: () => import("/app/app/pages/(auth)/sign-up.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profile_45PgpRRXTDqUPW_ZPIJvKfpmUK6wJHnqQhpJZ2q_45zxg4Meta || {},
    component: () => import("/app/app/pages/(user)/profile.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingswq_456a8ydbgG6wbfMwCpfnhex_45T_45qsHYXpOoJDGW8drsMeta || {},
    component: () => import("/app/app/pages/(user)/settings.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/app/pages/(legal)/contacts.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordsTA1Hg21lfZA2wcmB6jR8w5pIrmvpYmrv4pi6JGXTjkMeta || {},
    component: () => import("/app/app/pages/(auth)/reset-password.vue")
  },
  {
    name: "security-info",
    path: "/security-info",
    component: () => import("/app/app/pages/(legal)/security-info.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/app/app/pages/(legal)/privacy-policy.vue")
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/app/app/pages/(legal)/terms-of-service.vue")
  },
  {
    name: "md-username",
    path: "/md/:username()",
    component: () => import("/app/app/pages/(profiles)/md/[username].vue")
  },
  {
    name: "ph-username",
    path: "/ph/:username()",
    component: () => import("/app/app/pages/(profiles)/ph/[username].vue")
  },
  {
    name: "confirmation-token",
    path: "/confirmation/:token()",
    meta: _91token_93Xl8a0U68WgXIQ0MAb6WaLnGySeLQOa1wMzeM_hOTAy0Meta || {},
    component: () => import("/app/app/pages/(auth)/confirmation/[token].vue")
  },
  {
    name: "reset-password-confirm-token",
    path: "/reset-password-confirm/:token()",
    meta: _91token_93yYDVE68RAJ_2mdcpcl0hPAsSvrmQF2ub0xKmiqPloU4Meta || {},
    component: () => import("/app/app/pages/(auth)/reset-password-confirm/[token].vue")
  }
]
export default defineAppConfig({
  icon: {
    size: '24px',
    mode: 'svg',
    aliases: {
      uk: 'flagpack:ua',
      en: 'flagpack:us',
    },
  },
})

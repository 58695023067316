<script setup lang="ts">
const { t } = useI18n()

useHead({ title: `Redexy - ${t('not_found.description')}` })
</script>

<template>
  <NuxtLayout name="plain">
    <NotFound />
  </NuxtLayout>
</template>

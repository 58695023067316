<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    icon?: string
    size?: 'small' | 'medium' | 'large'
    color?: 'green' | 'gray' | 'red' | 'transparent'
    shape?: 'rectangle' | 'circle' | 'square'
    to?: string
    layout?: 'horizontal' | 'vertical'
    loading?: boolean
  }>(),
  {
    icon: undefined,
    size: 'medium',
    color: 'green',
    shape: 'rectangle',
    to: undefined,
    layout: 'horizontal',
    loading: false,
  },
)

const classes = computed(() => [
  props.size,
  props.shape,
  props.color,
  { vertical: props.layout === 'vertical' },
])
</script>

<template>
  <NuxtLink
    v-if="to"
    class="btn"
    :class="classes"
    :to="to"
  >
    <template v-if="icon">
      <Icon
        class="icon"
        :name="icon"
      />
    </template>

    <slot v-if="shape === 'rectangle'" />
  </NuxtLink>

  <button
    v-else
    class="btn relative"
    :class="classes"
  >
    <Loader
      v-if="loading"
      class="absolute inset-0"
    />

    <div
      class="inline-flex gap-2 items-center justify-center"
      :class="loading ? 'opacity-0' : 'opacity-100'"
    >
      <template v-if="icon">
        <Icon
          class="icon"
          :name="icon"
        />
      </template>

      <slot v-if="shape === 'rectangle'" />
    </div>
  </button>
</template>

<style scoped>
@reference '~/assets/css/main.css';

.btn {
  @apply
    inline-flex
    gap-2
    items-center
    justify-center
    tracking-wide
    whitespace-nowrap
    border
    cursor-pointer
    text-button-text
    focus-visible:outline-none
    disabled:cursor-not-allowed disabled:shadow-none
    disabled:bg-button-gray-bg
    disabled:border-button-gray-border
    disabled:hover:bg-button-gray-bg
    disabled:hover:border-button-gray-border;
}

.vertical {
  @apply flex-col;
}

.small {
  @apply px-3 h-8 text-sm;
}

.small .icon {
  @apply size-4;
}

.small.square,
.small.circle {
  @apply w-8;
}

.medium {
  @apply px-6 h-12;
}

.medium .icon {
  @apply size-5;
}

.large {
  @apply px-10 h-16;
}

.large.vertical {
  @apply px-8 h-24;
}

.large .icon {
  @apply size-6;
}

.rectangle {
  @apply rounded-md;
}

.square {
  @apply rounded-md aspect-square p-2;
}

.circle {
  @apply rounded-full aspect-square p-2;
}

.green {
  @apply
    bg-button-green-bg
    hover:bg-button-green-bg-hover

    border-button-green-border
    hover:border-button-green-border-hover;
}

.gray {
  @apply
    bg-button-gray-bg
    hover:bg-button-gray-bg-hover

    border-button-gray-border
    hover:border-button-gray-border-hover;
}

.red {
  @apply
    bg-button-red-bg
    hover:bg-button-red-bg-hover

    border-button-red-border
    hover:border-button-red-border-hover;
}

.transparent {
  @apply
    bg-transparent
    hover:bg-button-gray-bg-hover

    border-transparent
}
</style>

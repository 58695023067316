<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <div class="grid h-screen place-content-center">
    <div class="text-center">
      <h1 class="text-9xl font-black text-base-heading">
        {{ t('not_found.header') }}
      </h1>

      <p class="mt-2 text-2xl font-light">
        {{ t('not_found.description') }}
      </p>

      <div class="mt-20 flex justify-center gap-4">
        <Button to="/">
          {{ t('not_found.go_back_link') }}
        </Button>
      </div>
    </div>
  </div>
</template>

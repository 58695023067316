<template>
  <div class="flex gap-1 items-center justify-center">
    <div class="dot size-2 rounded-full bg-white" />
    <div class="dot size-2 rounded-full bg-white" />
    <div class="dot size-2 rounded-full bg-white" />
  </div>
</template>

<style scoped>
.dot {
  animation: bounce 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) { animation-delay: -0.32s; }
.dot:nth-child(2) { animation-delay: -0.16s; }

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0) }
  40% { transform: scale(1) }
}
</style>

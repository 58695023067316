import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_J7RC1KrgivhQR08jIXM9YUHtEN_ULOYJWarTYilMvaM from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_7443ce2584ab235311aaa5d171537ba8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VrsU3pC8vlaAeYeSsE_ohAquGYvefw0uZNHQtMwYvvE from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_7443ce2584ab235311aaa5d171537ba8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BBP4nphjplz39SVB54dBZK6iuXiBcge7XEJt3mEGNAA from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_7443ce2584ab235311aaa5d171537ba8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_20fiIev1rGBq_eBf9H9HGXRTMjzhSqGnRNT_7xjIisk from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_7443ce2584ab235311aaa5d171537ba8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jJPvZsXSaA0jEjBpdYmHfRjAsdep72Ym_6PkoHYCKr0 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_7443ce2584ab235311aaa5d171537ba8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_pfhQQ_3k33mqNx6IKovpZ50W8UjHIJWBNcbvGFR2Xn4 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_7443ce2584ab235311aaa5d171537ba8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0MDp83tJdGNGms53r6hAR7ACmxsJnbKzYeX4zqhta_g from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_7443ce2584ab235311aaa5d171537ba8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/app/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_uZShoe9wI5MKUFniCLv99yrtEzOFi1xbdnBETUXMojs from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_7443ce2584ab235311aaa5d171537ba8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_cYkm7_GoPNdjaNzF2uDqwoUochtEDtNxUNHV3CuwUsk from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_a17e21bd26f2b564b2f18fe971e4489d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_Ks8zTcu7g4Tn0NkE3syz_KGwPndX_0NM9sK6gm7UzW0 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_a17e21bd26f2b564b2f18fe971e4489d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_69lvZRkiUqDGgrn_SRSLHT8M31Yz0AYbXur7mUA19PQ from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_a17e21bd26f2b564b2f18fe971e4489d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_mwfrA0XxQ6z2xO5ypU43u6_gA0vti7OHiuStU3RHBTs from "/app/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.4_@types+node@22.13.17_jiti@2.4.2_lightningcs_cf73e1cfc106d0900a1d27d150eae9de/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import toast_client_S_2iOiDi0XdqP11urGO00yk625CbYVITLMfTy4JUsDk from "/app/app/plugins/toast.client.ts";
import urql_t9p8JIAfp3hNTh1f_FERMYwuXgMlho9pnMHB3mefCnE from "/app/app/plugins/urql.ts";
import vue_masonry_wall_BeGSXOcSex_9jLvYqIQO_43gxbfd8xAKzvENeXYB5nY from "/app/app/plugins/vue-masonry-wall.ts";
import ssg_detect_VK2jhLukhVrrJOmtrqgLqowu1FPTa7wk__ce5wLG3Tk from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_a17e21bd26f2b564b2f18fe971e4489d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_J7RC1KrgivhQR08jIXM9YUHtEN_ULOYJWarTYilMvaM,
  unhead_VrsU3pC8vlaAeYeSsE_ohAquGYvefw0uZNHQtMwYvvE,
  router_BBP4nphjplz39SVB54dBZK6iuXiBcge7XEJt3mEGNAA,
  payload_client_20fiIev1rGBq_eBf9H9HGXRTMjzhSqGnRNT_7xjIisk,
  navigation_repaint_client_jJPvZsXSaA0jEjBpdYmHfRjAsdep72Ym_6PkoHYCKr0,
  check_outdated_build_client_pfhQQ_3k33mqNx6IKovpZ50W8UjHIJWBNcbvGFR2Xn4,
  chunk_reload_client_0MDp83tJdGNGms53r6hAR7ACmxsJnbKzYeX4zqhta_g,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_uZShoe9wI5MKUFniCLv99yrtEzOFi1xbdnBETUXMojs,
  switch_locale_path_ssr_cYkm7_GoPNdjaNzF2uDqwoUochtEDtNxUNHV3CuwUsk,
  route_locale_detect_Ks8zTcu7g4Tn0NkE3syz_KGwPndX_0NM9sK6gm7UzW0,
  i18n_69lvZRkiUqDGgrn_SRSLHT8M31Yz0AYbXur7mUA19PQ,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_mwfrA0XxQ6z2xO5ypU43u6_gA0vti7OHiuStU3RHBTs,
  toast_client_S_2iOiDi0XdqP11urGO00yk625CbYVITLMfTy4JUsDk,
  urql_t9p8JIAfp3hNTh1f_FERMYwuXgMlho9pnMHB3mefCnE,
  vue_masonry_wall_BeGSXOcSex_9jLvYqIQO_43gxbfd8xAKzvENeXYB5nY,
  ssg_detect_VK2jhLukhVrrJOmtrqgLqowu1FPTa7wk__ce5wLG3Tk
]